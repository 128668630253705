import React from 'react';
import '../styles/Home.css';
import homeImage from '../assets/images/home.png';

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <div className="image-container">
        <a href="https://youtu.be/twiBGm76BVI?si=5kakQgQwbK625nMw" target="_blank" rel="noopener noreferrer" className="image-link">
          <img src={homeImage} alt="homeImage" className="background-img" />
        </a>
      </div>
    </div>
  );
};

export default Home;
