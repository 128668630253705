import React from 'react';
import logo from '../assets/images/logo_ps.png';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer-container">
      <div className="footer-top">
        <div className="footer-left">
          <img src={logo} alt="Logo" className="footer-logo" />
        </div>

        <div className="footer-center">
          <p>115 BPM</p>
        </div>

        <div className="footer-right">
          <p>SHARE</p>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/color/48/000000/twitter--v1.png" alt="X (formerly Twitter)" />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="Facebook" />
          </a>
        </div>
      </div>

      <div className="footer-bottom ">
        <p>&copy; 2024 Yuzux. ShinonomeKyo.</p>
      </div>
    </footer>
  );
};

export default Footer;
