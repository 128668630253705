import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';
import '../styles/Header.css';

const Header: React.FC = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`header-container ${isScrolled ? 'scrolled' : ''}`}>
        <div className="header-content">
            <img src={logo} alt="D4DJ Logo" className="logo" />
            <nav>
            <ul className="nav-links">
                <li><a href="#news">News</a></li>
                <li><a href="#qui-je-suis">Qui je suis</a></li>
                <li><a href="#projet">Projet</a></li>
            </ul>
            </nav>

            <nav className="social-nav">
            <span className="social-text">Social</span>
            <div className="social-icons">
                <a href="https://twitter.com/ShinonomeKyo" target="_blank" rel="noopener noreferrer">
                <img src="https://img.icons8.com/color/48/000000/twitter--v1.png" alt="X (formerly Twitter)" />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <img src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="Facebook" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <img src="https://img.icons8.com/color/48/000000/instagram-new.png" alt="Instagram" />
                </a>
                <a href="https://github.com/Yuzux" target="_blank" rel="noopener noreferrer">
                <img src="https://img.icons8.com/color/48/000000/github.png" alt="GitHub" />
                </a>
            </div>
            </nav>
        </div>
        </header>
    );
};

export default Header;
