import React from 'react';
import '../styles/About.css';

const About: React.FC = () => {
    return (
        <div className="about-background">
            <div className="about-container">
                <h1 className="about-title">About</h1>
                <p className="about-text">
                    I’ve learned from experience that the place to save lives is in the field.
                </p>
                <p className="about-text">
                    Not an office.
                </p>
                <p className="about-text">
                    And sometimes, the only way to save a live…
                </p>
                <p className="about-text">
                    Is to take one.
                </p>
                <p className="about-text">
                    "Gustave Kateb"
                </p>
            </div>
        </div>
    );
};

export default About;
