import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
//import NewsBar from './components/NewsBar';
import About from './components/About';
import './App.css';

const App: React.FC = () => {
  return (
    <div id="root">
        <Header />
        <div className="main-content">
          <Home />
          <About />
        </div>
        <Footer />
    </div>
  );
};

export default App;
